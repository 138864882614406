import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContentContext } from "../providers/ContentContext";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Modal } from "antd";

const Payment = ({ isOpen, onClose, pack, switchOn }) => {
  let { url, openWarningNotification, openErrorNotification } =
    useContentContext();

  const token = localStorage.getItem("token");

  const [stripePromis, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const data = {
    user_id: localStorage.getItem("userid"),
    amount: switchOn
      ? parseInt(pack?.annual_price)
      : parseInt(pack?.monthly_price),
    currency: "usd",
    months: switchOn ? '12' : '1'
  };

  useEffect(() => {
    setStripePromise(
      loadStripe(
        "pk_test_51K24jPDlUTLGsrm9wEpWIjbCe7ZwdU3G0EQoMKL1L8aeRYDiYZQ1qxjZS8Lfem3QIjLEPDTE3RzR3mdZoUHk8lEq005wTXQPgt"
      )
    );
  }, []);

  useEffect(() => {
    const data = {
      user_id: localStorage.getItem("userid"),
      amount: switchOn
        ? parseInt(pack?.annual_price)
        : parseInt(pack?.monthly_price),
      currency: "usd",
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "transaction/initial", data, config)
      .then((response) => {
        if (response.data.success) {
          setClientSecret(response.data.output.client_secret);
        } else {
          openWarningNotification("Action Failed!", response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  }, []);

  const handleCancel = () => {
    setClientSecret("");
    onClose(false);
  };

  return (
    <Modal
      title="PAYMENT"
      open={isOpen}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: "none" } }}
      width={500}
    >
      <div>
        {stripePromis && clientSecret && (
          <Elements stripe={stripePromis} options={{ clientSecret }}>
            <CheckoutForm data={data} pack={pack} handleCancel={handleCancel} />
          </Elements>
        )}
      </div>
    </Modal>
  );
};

export default Payment;
