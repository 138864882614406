import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { EditPackage, NewPackage, PackagePurchase } from "../components";
import axios from "axios";
import { useContentContext } from "../providers/ContentContext";

const Services = () => {
  let {
    url,
    openWarningNotification,
    openErrorNotification,
    openSuccessNotification,
  } = useContentContext();

  const token = localStorage.getItem("token");
  const usertype = localStorage.getItem("usertype");
  const [packages, setPackages] = useState([]);
  const [isNewPackageDialogOpen, setIsNewPackageDialogOpen] = useState(false);
  const [isEditPackageDialogOpen, setIsEditPackageDialogOpen] = useState(false);
  const [isPurchasePackageDialogOpen, setIsPurchasePackageDialogOpen] =
    useState(false);
  const [selectedPackage, setSelectedPackage] = useState();

  useEffect(() => {
    loadPackages();
  }, []);

  const openNewPackageDialog = () => {
    setIsNewPackageDialogOpen(true);
  };
  const closeNewPackageDialog = () => {
    setIsNewPackageDialogOpen(false);
  };
  const openEditPackageDialog = (pack) => {
    setSelectedPackage(pack);
    setIsEditPackageDialogOpen(true);
  };
  const closeEditPackageDialog = () => {
    setIsEditPackageDialogOpen(false);
  };
  const closePurchasePackageDialog = () => {
    setIsPurchasePackageDialogOpen(false);
  };

  const loadPackages = () => {
    const data = {
      status: 1,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "package/all", data, config)
      .then((response) => {
        if (response.data.success) {
          setPackages(response.data.output);
        } else {
          openWarningNotification(
            "Data Loading Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const deletePackage = (pack) => {
    const data = {
      package_id: pack.id,
      status: 0,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "package/delete", data, config)
      .then((response) => {
        if (response.data.success) {
          openSuccessNotification(
            "Action Completed!",
            "Package deleted successfully."
          );
          loadPackages();
        } else {
          openWarningNotification(
            "Action Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const purchasePackage = (pack) => {
    setSelectedPackage(pack);
    setIsPurchasePackageDialogOpen(true);
  };

  return (
    <>
      <div className="flex flex-col w-full gap-4">
        <div className="flex mt-4">
          <Card className="flex flex-col bg-[#28253B] border-none text-white w-full">
            <div className="flex flex-row justify-between">
              <div className="flex text-[24px] font-medium">PACKAGES</div>
              <div className="flex flex-row gap-4">
                <div>
                  {usertype === "1" ? (
                    <>
                      <Button
                        type="primary"
                        icon={
                          <PlusOutlined className="p-1 max-md:p-0 text-white" />
                        }
                        ghost
                        onClick={openNewPackageDialog}
                      >
                        <label className="cursor-pointer max-md:hidden visible text-white">
                          New Package
                        </label>
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="overflow-x-auto w-full">
              <div className="flex flex-row gap-4 p-4">
                {packages.length === 0 ? (
                  "No Packages"
                ) : (
                  <>
                    {packages.map((pack) => {
                      return (
                        <div
                          key={pack.id}
                          className="flex flexx flex-col w-[200px] bg-[#59595a50] p-2 rounded-2xl shadow-md shadow-[#a0a0a050] relative hover:opacity-60"
                        >
                          <div className="font-bold text-2xl text-white text-center">
                            {pack.name}
                          </div>
                          <div className="text-[12px] text-center text-[#919090] mt-2">
                            Monthly
                          </div>
                          <div className="font-bold text-4xl text-white text-center py-3">
                            ${pack.monthly_price}
                          </div>
                          <div className="text-[16px] text-center text-[#919090]">
                            Yearly ${pack.annual_price}
                          </div>
                          <div className="font-normal text-lg text-white text-center">
                            {pack.description}
                          </div>
                          <div className="absolute opacity-0 z-50 top-0 left-0 w-full h-full flex justify-center items-center ease-linear transition-all duration-300 hover:opacity-100">
                            {usertype === "1" ? (
                              <>
                                <button
                                  className="bg-white text-black px-2 py-1 rounded-lg text-lg font-bold m-2"
                                  onClick={() => {
                                    deletePackage(pack);
                                  }}
                                >
                                  <DeleteOutlined className="text-red-500" />
                                </button>
                                <button
                                  className="bg-white text-black px-2 py-1 rounded-lg text-lg font-bold m-2"
                                  onClick={() => {
                                    openEditPackageDialog(pack);
                                  }}
                                >
                                  <EditOutlined />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="bg-white text-black px-2 py-1 rounded-lg text-lg font-bold m-2"
                                  onClick={() => {
                                    purchasePackage(pack);
                                  }}
                                >
                                  Buy
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </Card>
        </div>
        <div className="flex">
          <Card className="flex flex-col w-full bg-[#28253B] border-none text-white">
            <div className="flex text-[24px] font-medium">PACKAGE STATISTICS</div>
          </Card>
        </div>
      </div>
      <NewPackage
        isOpen={isNewPackageDialogOpen}
        onClose={closeNewPackageDialog}
        loadPackages={loadPackages}
      />
      <EditPackage
        isOpen={isEditPackageDialogOpen}
        onClose={closeEditPackageDialog}
        loadPackages={loadPackages}
        pack={selectedPackage}
      />
      <PackagePurchase
        isOpen={isPurchasePackageDialogOpen}
        onClose={closePurchasePackageDialog}
        pack={selectedPackage}
      />
    </>
  );
};

export default Services;
