import { Modal } from "antd";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useContentContext } from "../../providers/ContentContext";
import axios from "axios";

const NewStrategy = ({ isOpen, onClose, loadStrategies }) => {
  let {
    url,
    openWarningNotification,
    openErrorNotification,
    openSuccessNotification,
  } = useContentContext();
  const token = localStorage.getItem("token");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleOk = () => {
    if ((title !== "") & (description !== "")) {
      const data = {
        user_id: localStorage.getItem("userid"),
        title: title,
        description: description,
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      axios
        .post(url + "strategy/add", data, config)
        .then((response) => {
          if (response.data.success) {
            openSuccessNotification("Strategy Added!", response.data.message);
            loadStrategies();
            reset();
          } else {
            openWarningNotification(
              "Strategy Adding Failed!",
              response.data.message
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            openErrorNotification("Error!", error.response.data.message);
            localStorage.clear();
            window.location.replace("/login");
          } else {
            openErrorNotification("Error!", error.response.data.message);
          }
        });
    } else {
      openErrorNotification(
        "Missing Required Fields!",
        "All fields are required, Please fill them all."
      );
    }
  };

  const handleCancel = () => {
    reset();
  };

  const reset = () => {
    setTitle("");
    setDescription("");
    onClose();
  };
  return (
    <Modal
      title="New Strategy"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Add"
      okButtonProps={{ style: { backgroundColor: "#2265e2" } }}
      width={500}
    >
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Title <span className="text-red-600">*</span>
            </span>
            <input
              type="text"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              required
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 mb-12">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Description <span className="text-red-600">*</span>
            </span>

            <ReactQuill
              theme="snow"
              value={description}
              onChange={(e) => {
                setDescription(e);
              }}
              className="h-48"
              required
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewStrategy;
