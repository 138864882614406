import { DatePicker, Modal, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContentContext } from "../../providers/ContentContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment/moment";

dayjs.extend(customParseFormat);

const EditLog = ({ isOpen, onClose, trade }) => {
  const [accountId, setAccountId] = useState(trade?.account_id);
  const [openDate, setOpenDate] = useState(
    dayjs(trade?.open_date, "YYYY-MM-DD")
  );
  const [symbol, setSymbol] = useState(trade?.symbol);
  const [status, setStatus] = useState(trade?.status);
  const [closeDate, setCloseDate] = useState(
    dayjs(trade?.close_date, "YYYY-MM-DD")
  );
  const [profitLost, setProfitLoss] = useState(trade?.profit_lost);
  const [rr, setRr] = useState(trade?.rr);
  const [tp, setTp] = useState(trade?.tp);
  const [longShort, setLongShort] = useState(trade?.long_short);
  const [entryPrice, setEntryPrice] = useState(trade?.entry_price);
  const [sl, setSl] = useState(trade?.sl);
  const [notes, setNotes] = useState(trade?.notes);

  let {
    url,
    openWarningNotification,
    openErrorNotification,
    openSuccessNotification,
  } = useContentContext();
  const token = localStorage.getItem("token");

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    loadAccounts();
    setData();
  }, []);

  const setData = () => {
    setAccountId(trade?.account_id);
    setOpenDate(dayjs(trade?.open_date, "YYYY-MM-DD"));
    setCloseDate(dayjs(trade?.close_date, "YYYY-MM-DD"));
    setSymbol(trade?.symbol);
    setStatus(trade?.status);
    setProfitLoss(trade?.profit_lost);
    setRr(trade?.rr);
    setTp(trade?.tp);
    setLongShort(trade?.long_short);
    setEntryPrice(trade?.entry_price);
    setSl(trade?.sl);
    setNotes(trade?.notes);
  };

  const loadAccounts = () => {
    const data = {
      user_id: localStorage.getItem("userid"),
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "account/all", data, config)
      .then((response) => {
        if (response.data.success) {
          var arr = [];
          response.data.output.forEach((account) => {
            const data = {
              value: account.id,
              label: account.name,
            };
            arr.push(data);
          });
          setAccounts(arr);
        } else {
          openWarningNotification(
            "Data Loading Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const reset = () => {
    setAccountId(1);
    setOpenDate("");
    setSymbol("");
    setStatus(1);
    setCloseDate("");
    setEntryPrice("");
    setProfitLoss("");
    setRr("");
    setTp("");
    setLongShort(1);
    setSl("");
    setNotes("");
  };

  const handleOk = () => {
    if (accountId === undefined) {
      openErrorNotification(
        "Account Required",
        "Please fill all required fields"
      );
    } else if (openDate === "") {
      openErrorNotification(
        "Open Date Required",
        "Please fill all required fields"
      );
    } else if (symbol === "") {
      openErrorNotification(
        "Symbol Required",
        "Please fill all required fields"
      );
    } else if (status === undefined) {
      openErrorNotification(
        "Status Required",
        "Please fill all required fields"
      );
    } else if (closeDate === "") {
      openErrorNotification(
        "Close Date Required",
        "Please fill all required fields"
      );
    } else if (entryPrice === "") {
      openErrorNotification(
        "Entry Price Required",
        "Please fill all required fields"
      );
    } else if (profitLost === "") {
      openErrorNotification(
        "Profit/Lost Required",
        "Please fill all required fields"
      );
    } else if (rr === "") {
      openErrorNotification("R.R Required", "Please fill all required fields");
    } else if (tp === "") {
      openErrorNotification("T.P Required", "Please fill all required fields");
    } else if (longShort === undefined) {
      openErrorNotification(
        "Long/Short Required",
        "Please fill all required fields"
      );
    } else if (sl === "") {
      openErrorNotification("S.L Required", "Please fill all required fields");
    } else {
      const data = {
        account_transaction_id: trade.id,
        user_id: localStorage.getItem("userid"),
        account_id: accountId,
        open_date: moment(openDate?.$d).format("YYYY-MM-DD"),
        symbol: symbol,
        status: status,
        close_date: moment(closeDate?.$d).format("YYYY-MM-DD"),
        entry_price: entryPrice,
        profit_lost: profitLost,
        rr: rr,
        tp: tp,
        long_short: longShort,
        sl: sl,
        notes: notes,
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      axios
        .post(url + "account/transaction/edit", data, config)
        .then((response) => {
          if (response.data.success) {
            openSuccessNotification("New Trade Updated!", response.data.message);
            handleCancel();
          } else {
            openWarningNotification(
              "Data Loading Failed!",
              response.data.message + " Please refresh the page."
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            openErrorNotification("Error!", error.response.data.message);
            localStorage.clear();
            window.location.replace("/login");
          } else {
            openErrorNotification("Error!", error.response.data.message);
          }
        });
    }
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      title="Edit Trade"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Update"
      okButtonProps={{ style: { backgroundColor: "#28253B" } }}
      width={700}
      maskClosable={false}
    >
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Account
              <span className="text-red-600">*</span>
            </span>
            <Select
              placeholder="Select Account"
              className="border-[1px] border-[#707070] rounded-md"
              bordered={false}
              onChange={(e) => {
                setAccountId(e);
              }}
              defaultValue={accountId}
              options={accounts}
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Symbol
              <span className="text-red-600">*</span>
            </span>
            <input
              type="text"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={symbol}
              onChange={(e) => {
                setSymbol(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Open date <span className="text-red-600">*</span>
            </span>
            <DatePicker
              value={openDate}
              onChange={(date) => {
                setOpenDate(date);
              }}
              className="flex w-full border-[1px] border-[#707070] rounded-md px-2 h-8"
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Close Date<span className="text-red-600">*</span>
            </span>
            <DatePicker
              value={closeDate}
              onChange={(date) => {
                setCloseDate(date);
              }}
              className="flex w-full border-[1px] border-[#707070] rounded-md px-2 h-8"
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Profit/Loss<span className="text-red-600">*</span>
            </span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={profitLost}
              onChange={(e) => {
                setProfitLoss(e.target.value);
              }}
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Entry Price<span className="text-red-600">*</span>
            </span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={entryPrice}
              onChange={(e) => {
                setEntryPrice(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              S.L<span className="text-red-600">*</span>
            </span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={sl}
              onChange={(e) => {
                setSl(e.target.value);
              }}
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              R.R<span className="text-red-600">*</span>
            </span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={rr}
              onChange={(e) => {
                setRr(e.target.value);
              }}
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              T.P<span className="text-red-600">*</span>
            </span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={tp}
              onChange={(e) => {
                setTp(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Long/Short
              <span className="text-red-600">*</span>
            </span>
            <Select
              placeholder="Select Long/Short"
              className="border-[1px] border-[#707070] rounded-md"
              bordered={false}
              onChange={(e) => {
                setLongShort(e);
              }}
              defaultValue={longShort}
              options={[
                { value: 0, label: "Short" },
                { value: 1, label: "Long" },
              ]}
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Status
              <span className="text-red-600">*</span>
            </span>
            <Select
              placeholder="Select Status"
              className="border-[1px] border-[#707070] rounded-md"
              bordered={false}
              onChange={(e) => {
                setStatus(e);
              }}
              defaultValue={status}
              options={[
                { value: 1, label: "Open" },
                { value: 2, label: "Close" },
              ]}
              required
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">Notes</span>
            <input
              type="text"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              required
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditLog;
