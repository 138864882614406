import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { Dashboard, Login, Calender, Register, Services, Transactions, Portfolio, Strategy, TradeAssistant, TradeLog } from './pages';
import { Main } from './layout';

function App() {
  const token = localStorage.getItem('token');

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        {token !== null ? (
          <Route path="/" element={<Main />}>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/calender" element={<Calender />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/strategy" element={<Strategy />} />
            <Route exact path="/trade-assistant" element={<TradeAssistant />} />
            <Route exact path="/tradelog" element={<TradeLog />} />
          </Route>
        ) : (
          <Route exact path="*" element={<Login />} />
        )}

      </Routes>
    </BrowserRouter>
  );
}

export default App;
