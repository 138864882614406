import React, { useEffect, useState } from "react";
import {
  AlignLeftOutlined,
  AlignRightOutlined,
  UserOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Layout, Button, Dropdown } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";

import { Route, Routes } from "react-router-dom";
import {
  Calender,
  Dashboard,
  Portfolio,
  Services,
  Strategy,
  TradeAssistant,
  TradeLog,
  Transactions,
} from "../pages";
import Sidebar from "./Sidebar";

const Main = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [location, setLocation] = useState("Dashboard");

  useEffect(() => {
    if (window.innerWidth < 426) {
      setCollapsed(true);
    }
  }, []);

  const updatePath = (path) => {
    if (path === "/dashboard") {
      setLocation("Dashboard");
    } else if (path === "/services") {
      setLocation("Services");
    } else if (path === "/portfolio") {
      setLocation("Portfolio");
    } else if (path === "/strategy") {
      setLocation("Strategy");
    } else if (path === "/trade-assistant") {
      setLocation("Trade Assistant");
    } else if (path === "/tradelog") {
      setLocation("Trade Log");
    } else {
      setLocation("Dashboard");
    }
  };

  // Navigation Menu Options
  const items = [
    {
      label: "Logout",
      key: "1",
      icon: <PoweroffOutlined />,
    },
  ];

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      localStorage.clear();
      window.location.replace("/login");
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <Layout className="h-screen w-full">
      <Sider
        className={
          collapsed
            ? "max-md:hidden bg-[#1E2128] relative"
            : "visible sider bg-[#1E2128] relative"
        }
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ background: "#1E2128" }}
      >
        <Sidebar collapsed={collapsed} updatePath={updatePath} />
      </Sider>
      <Layout className="bg-[#525252]">
        <Header style={{ padding: 0, background: "#28253B" }}>
          <div className="flex flex-row text-white">
            <div className="flex flex-row justify-between items-baseline w-full">
              <Button
                type="text"
                icon={
                  collapsed ? (
                    <AlignLeftOutlined className="text-white" />
                  ) : (
                    <AlignRightOutlined className="text-white" />
                  )
                }
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                  zIndex: 999,
                }}
              />
              <div className="flex text-white font-semibold text-2xl w-full">
                {location}
              </div>
            </div>
            <Dropdown.Button
              menu={menuProps}
              icon={<UserOutlined className="text-white" />}
              className="flex justify-end m-4 text-white"
            >
              <div className="text-white">
                Welcome! {localStorage.getItem("name")}
              </div>
            </Dropdown.Button>
          </div>
        </Header>

        <Content className="m-[24px] p-[24px] bg-[#525252] rounded-md h-full overflow-y-scroll">
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/calender" element={<Calender />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/strategy" element={<Strategy />} />
            <Route exact path="/trade-assistant" element={<TradeAssistant />} />
            <Route exact path="/tradelog" element={<TradeLog />} />
          </Routes>
        </Content>
        <Footer className="text-center pt-0 bg-[#525252]">
          Copyright 2023 © ALL RIGHTS RESERVED. Design by{" "}
          <a href="https://marketsniper.pro" target="_blank" rel="noreferrer">
            Market Sniper Pro
          </a>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Main;
