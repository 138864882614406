import { Modal } from "antd";
import React, { useState } from "react";
import { useContentContext } from "../providers/ContentContext";
import axios from "axios";

const NewPackage = ({ isOpen, onClose, loadPackages }) => {
  let {
    url,
    openSuccessNotification,
    openErrorNotification,
    openWarningNotification,
  } = useContentContext();
  const token = localStorage.getItem("token");

  const [packageName, setPackageName] = useState();
  const [packageDescription, setPackageDescription] = useState();
  const [monthlyPrice, setMonthlyPrice] = useState();
  const [yearlyPrice, setYearlyPrice] = useState();

  const handleOk = () => {
    if (
      (packageName !== undefined) &
      (packageName !== "") &
      (packageDescription !== undefined) &
      (packageDescription !== "") &
      (monthlyPrice !== undefined) &
      (monthlyPrice !== "") &
      (yearlyPrice !== undefined) &
      (yearlyPrice !== "")
    ) {
      const data = {
        name: packageName,
        description: packageDescription,
        monthly_price: monthlyPrice,
        annual_price: yearlyPrice,
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      axios
        .post(url + "package/add", data, config)
        .then((response) => {
          if (response.data.success) {
            openSuccessNotification(
              "Package added successfully!",
              response.data.message
            );
            loadPackages();
            handleCancel();
          } else {
            openWarningNotification(
              "Package Adding Failed!",
              response.data.message
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            openErrorNotification("Error!", error.response.data.message);
            localStorage.clear();
            window.location.replace("/login");
          } else {
            openErrorNotification("Error!", error.response.data.message);
          }
        });
    } else {
      openWarningNotification(
        "All fields are required!",
        "Please fill all fields."
      );
    }
  };

  const handleCancel = () => {
    reset();
    onClose(false);
  };

  const reset = () => {
    setPackageName("");
    setPackageDescription("");
    setMonthlyPrice("");
    setYearlyPrice("");
  };

  return (
    <Modal
      title="New Package"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Add"
      okButtonProps={{ style: { backgroundColor: "#2265e2" } }}
      width={500}
    >
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Package Name <span className="text-red-600">*</span>
            </span>
            <input
              type="text"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              required
              value={packageName}
              onChange={(e) => {
                setPackageName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Package Description <span className="text-red-600">*</span>
            </span>
            <textarea
              type="text"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              required
              value={packageDescription}
              onChange={(e) => {
                setPackageDescription(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Monthly Price <span className="text-red-600">*</span>
            </span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              placeholder="$ 0.00"
              required
              value={monthlyPrice}
              onChange={(e) => {
                setMonthlyPrice(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Yearly Price <span className="text-red-600">*</span>
            </span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              placeholder="$ 0.00"
              required
              value={yearlyPrice}
              onChange={(e) => {
                setYearlyPrice(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewPackage;
