import { Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useContentContext } from "../../providers/ContentContext";

const EditPortfolio = ({ isOpen, onClose, portfolio }) => {
  const [name, setName] = useState("");
  const [broker, setBroker] = useState('');
  const [type, setType] = useState('');
  const [deposit, setDeposit] = useState();
  const [balance, setBalance] = useState();

  let {
    url,
    openWarningNotification,
    openErrorNotification,
    openSuccessNotification,
  } = useContentContext();
  const token = localStorage.getItem("token");

  useEffect(() => {
    setData();
  }, [portfolio]);

  const setData = () => {
    setName(portfolio?.name);
    setBroker(portfolio?.broker_id);
    setType(portfolio?.account_type_id);
    setDeposit(portfolio?.deposite_withdraw);
    setBalance(portfolio?.balance);
  };

  const handleOk = () => {
    if (name === "") {
      openWarningNotification("Required!", "Portfolio Name Required!");
    } else if (deposit === "") {
      openWarningNotification("Required!", "Deposit/Withdraw Amount Required!");
    } else if (balance === "") {
      openWarningNotification("Required!", "Balance Amount Required!");
    } else if (broker === undefined) {
      openWarningNotification("Required!", "Broker/Exchange Required!");
    } else if (type === undefined) {
      openWarningNotification("Required!", "Account Type Required!");
    } else {
      const data = {
        account_id: portfolio.id,
        user_id: parseInt(localStorage.getItem("userid")),
        name: name,
        broker_id: broker,
        deposite_withdraw: parseFloat(deposit),
        account_type_id: type,
        balance: parseFloat(balance),
      };

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      axios
        .post(url + "account/edit", data, config)
        .then((response) => {
          if (response.data.success) {
            openSuccessNotification(
              "Portfolio Updated!",
              response.data.message + " Please refresh the page."
            );
            handleCancel();
          } else {
            openWarningNotification(
              "Portfolio Updating Failed!",
              response.data.message + " Please refresh the page."
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            openErrorNotification("Error!", error.response.data.message);
            localStorage.clear();
            window.location.replace("/login");
          } else {
            openErrorNotification("Error!", error.response.data.message);
          }
        });
    }
  };

  const handleCancel = () => {
    setName("");
    setBroker(1);
    setType(1);
    setDeposit();
    setBalance();
    onClose();
  };

  return (
    <Modal
      title="Edit Portfolio"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Update"
      okButtonProps={{ style: { backgroundColor: "#28253B" } }}
      width={700}
      maskClosable={false}
    >
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Name
              <span className="text-red-600">*</span>
            </span>
            <input
              type="text"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Broker/Exchange <span className="text-red-600">*</span>
            </span>
            <input
              placeholder="Select Broker/Exchange"
              type="text"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={broker}
              onChange={(e) => {
                setBroker(e.target.value);
              }}
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">
              Deposit/Withdraw <span className="text-red-600">*</span>
            </span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={deposit}
              onChange={(e) => {
                setDeposit(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex flex-col w-full">
            <span className="font-semibold">Asset Type</span>
            <input
              placeholder="Select Type"
              type="text"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={type}
              onChange={(e) => {
                setType(e.target.value);
              }}
              required
            />
          </div>
          <div className="flex flex-col w-full">
            <span className="font-semibold">Balance</span>
            <input
              type="number"
              className="border-[1px] border-[#707070] rounded-md px-2 h-8"
              value={balance}
              onChange={(e) => {
                setBalance(e.target.value);
              }}
              required
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditPortfolio;
