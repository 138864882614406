import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { Logo } from "../assets";
import { useContentContext } from "../providers/ContentContext";
import axios from "axios";

const Register = () => {
  let { url, openWarningNotification, openErrorNotification } =
    useContentContext();

  const onFinish = (values) => {
    const data = {
      user_type: 2,
      first_name: values.fname,
      last_name: values.lname,
      gender_id: 1,
      email_address: values.email,
      password: values.newpassword,
      password_confirmation: values.repassword,
      mobile_number: values.phone,
    };

    axios
      .post(url + "user/register", data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("token", response.data.output.token);
          localStorage.setItem("name", response.data.output.user.last_name);
          localStorage.setItem("userid", response.data.output.user.id);
          localStorage.setItem("usertype", response.data.output.user.user_type);

          window.location.replace("/dashboard");
        } else {
          openWarningNotification("Login Failed!", response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!", error.message);
        } else {
          openErrorNotification("Error!", error.message);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      window.location.replace("/dashboard");
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-screen justify-center items-center bg-[#1E2128]">
      <div className="flex flex-col justify-center items-center">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="flex flex-col justify-center items-center mt-4 bg-[#32353D] p-6 sm:px-8 px-4 max-sm:mx-2 rounded-2xl shadow-xl">
        <div className="text-lg font-medium text-[#58B638]">REGISTER</div>
        <Form
          name="register"
          className="flex flex-col sm:w-[400px] w-[250px] mt-8"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className="flex flex-row gap-2 w-full">
            <Form.Item
              name="fname"
              rules={[
                {
                  required: true,
                  type: "text",
                  message: "Please enter your First Name!",
                },
              ]}
              className="flex w-full"
            >
              <div>
                <div className="text-white w-full text-base pl-4">
                  First Name
                </div>
                <Input
                  type="text"
                  className="bg-[#282828] mt-1 border-none text-white w-full"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="lname"
              rules={[
                {
                  required: true,
                  type: "text",
                  message: "Please enter your Last Name!",
                },
              ]}
              className="flex w-full"
            >
              <div>
                <div className="text-white w-full text-base pl-4">
                  Last Name
                </div>
                <Input
                  type="text"
                  className="bg-[#282828] mt-1 border-none text-white"
                />
              </div>
            </Form.Item>
          </div>
          <div className="flex flex-row gap-3 w-full">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter your Email!",
                },
              ]}
              className="flex w-full"
            >
              <div>
                <div className="text-white w-full text-base pl-4">Email</div>
                <Input
                  type="email"
                  className="bg-[#282828] mt-1 border-none text-white"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  type: "text",
                  message: "Please enter your Phone Number!",
                },
              ]}
              className="flex w-full"
            >
              <div>
                <div className="text-white w-full text-base pl-4">
                  Phone Number
                </div>
                <Input
                  type="text"
                  className="bg-[#282828] mt-1 border-none text-white"
                />
              </div>
            </Form.Item>
          </div>
          <div className="flex flex-row gap-3 w-full">
            <Form.Item
              name="newpassword"
              rules={[
                {
                  type: "password",
                  required: true,
                  message: "Please enter New Password!",
                },
              ]}
              className="flex w-full"
            >
              <div>
                <div className="text-white w-full text-base pl-4">
                  New Password
                </div>
                <Input
                  type="password"
                  className="bg-[#282828] mt-1 border-none text-white"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="repassword"
              rules={[
                {
                  type: "password",
                  required: true,
                  message: "Please Re-Enter your Password!",
                },
              ]}
              className="flex w-full"
            >
              <div>
                <div className="text-white w-full text-base pl-4">
                  Re-Type Password
                </div>
                <Input
                  type="password"
                  className="bg-[#282828] mt-1 border-none text-white"
                />
              </div>
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full h-auto bg-[#58B638] mt-4 py-2 text-[32px]"
            >
              Register
            </Button>
          </Form.Item>
        </Form>
        <div className="flex w-full flex-row justify-between text-white">
          <div className="flex cursor-pointer">Can’t Register?</div>
          <div
            className="flex cursor-pointer"
            onClick={() => {
              window.location.replace("/login");
            }}
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
