import { Modal, Switch } from "antd";
import React, { useState } from "react";
import Payment from "./Payment";

const PackagePurchase = ({ isOpen, onClose, pack }) => {
  const [switchOn, setSwitchOn] = useState(false);
  const [isPaymentViewOpen, setIsPaymentViewOpen] = useState(false);

  const openPaymentViewDialog = () => {
    setIsPaymentViewOpen(true);
  };
  const closePaymentViewDialog = () => {
    setIsPaymentViewOpen(false);
  };

  const handleOk = () => {
    openPaymentViewDialog(true);
  };

  const handleCancel = () => {
    onClose(false);
  };

  const switched = (e) => {
    setSwitchOn(e);
  };

  return (
    <Modal
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Buy Now"
      okButtonProps={{ style: { backgroundColor: "#2265e2" } }}
      width={500}
    >
      <div className="flex flex-col">
        <div className="flex justify-center items-center text-2xl font-bold">
          {pack?.name}
        </div>
        <div className="flex flex-row justify-center items-center mt-4">
          <div className={`${!switchOn ? "text-blue-400" : ""}`}>Monthly</div>
          &nbsp;&nbsp;
          <Switch
            onChange={(e) => {
              switched(e);
            }}
            checked={switchOn}
            className="bg-[#7e7e7e]"
          />
          &nbsp;&nbsp;
          <div className={`${switchOn ? "text-blue-400" : ""}`}>Annually</div>
        </div>
        <div className="flex flex-row justify-center items-center text-lg mt-8">
          $
          <span className="text-6xl font-bold pb-4">
            {switchOn ? pack?.annual_price : pack?.monthly_price}
          </span>
        </div>
        <div className="flex flex-row justify-center items-center text-lg mt-4">
          {pack?.description}
        </div>
      </div>
      <Payment
        isOpen={isPaymentViewOpen}
        onClose={closePaymentViewDialog}
        pack={pack}
        switchOn={switchOn}
      />
    </Modal>
  );
};

export default PackagePurchase;
