import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { Logo } from "../assets";
import axios from "axios";
import { useContentContext } from "../providers/ContentContext";

const Login = () => {
  let { url, openWarningNotification, openErrorNotification } =
    useContentContext();

  const onFinish = (values) => {
    const data = {
      email_address: values.username,
      password: values.password,
    };

    axios
      .post(url + "user/login", data)
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("token", response.data.output.token);
          localStorage.setItem("name", response.data.output.last_name);
          localStorage.setItem("userid", response.data.output.id);
          localStorage.setItem("usertype", response.data.output.user_type);
          window.location.replace("/dashboard");
        } else {
          openWarningNotification("Login Failed!", response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          openErrorNotification("Internal Server Error!", error.message);
        } else {
          openErrorNotification("Error!", error.message);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      window.location.replace("/dashboard");
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-screen justify-center items-center bg-[#1E2128]">
      <div className="flex flex-col justify-center items-center">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="flex flex-col justify-center items-center mt-4 bg-[#32353D] p-6 sm:px-8 px-4 max-sm:mx-2 rounded-2xl shadow-xl">
        <div className="text-lg font-medium text-[#58B638]">LOGIN</div>
        <Form
          name="normal_login"
          className="flex flex-col sm:w-[300px] w-[250px] mt-8"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter your Email!",
              },
            ]}
          >
            <div>
              <div className="text-white text-center w-full text-base">
                E-Mail or User Name
              </div>
              <Input
                type="text"
                className="bg-[#282828] mt-1 border-none text-white"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your Password!",
              },
            ]}
          >
            <div>
              <div className="text-white text-center w-full text-base">
                Password
              </div>
              <Input
                type="password"
                className="bg-[#282828] mt-1 border-none text-white"
              />
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full h-auto bg-[#58B638] mt-4 py-2 text-[32px]"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <div className="flex w-full flex-row justify-between text-white">
          <div className="flex cursor-pointer">Can’t Login?</div>
          <div
            className="flex cursor-pointer"
            onClick={() => {
              window.location.replace("/register");
            }}
          >
            Register
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
