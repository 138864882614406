import { Button, Card, Divider, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { EditPortfolio, NewPortfolio } from "../components";
import axios from "axios";
import { useContentContext } from "../providers/ContentContext";

const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Broker/Exchange",
    dataIndex: "broker",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Depost/Withdraw",
    dataIndex: "depwithdraw",
  },
  {
    title: "Balance",
    dataIndex: "balance",
  },
  {
    title: "Actions",
    dataIndex: "actions",
  },
];

const Portfolio = () => {
  let {
    url,
    openWarningNotification,
    openErrorNotification,
    openSuccessNotification,
  } = useContentContext();
  const token = localStorage.getItem("token");

  const [isNewPortfolioOpen, setIsNewPortfolioOpen] = useState(false);
  const [isEditPortfolioOpen, setIsEditPortfolioOpen] = useState(false);

  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState();

  const openNewPortfolioModal = () => {
    setIsNewPortfolioOpen(true);
  };

  const closeNewPortfolioModal = () => {
    setIsNewPortfolioOpen(false);
    loadPortfolios();
  };

  const openEditPortfolioModal = (portfolio) => {
    setSelectedPortfolio(portfolio);
    setIsEditPortfolioOpen(true);
  };

  const closeEditPortfolioModal = () => {
    setIsEditPortfolioOpen(false);
    loadPortfolios();
  };

  useEffect(() => {
    loadPortfolios();
  }, []);

  const loadPortfolios = () => {
    const data = {
      user_id: localStorage.getItem("userid"),
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "account/all", data, config)
      .then((response) => {
        if (response.data.success) {
          var arr = [];
          var x = 0;
          response.data.output.forEach((account) => {
            if (account.is_active) {
              x = x + 1;
              let data = {
                id: x,
                name: account.name,
                broker: account.broker_id,
                type: account.account_type_id,
                depwithdraw: account.deposite_withdraw,
                balance: account.balance,
                actions: (
                  <>
                    <Popconfirm
                      title="Delete the Portfolio"
                      description="Are you sure to delete this portfolio?"
                      onConfirm={() => {
                        confirm(account);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      okButtonProps={{ style: { backgroundColor: "#28253B" } }}
                      cancelText="No"
                    >
                      <Button danger className="m-1">
                        Delete
                      </Button>
                    </Popconfirm>

                    <Button
                      className="bg-yellow-600 text-white hover:text-white m-1"
                      onClick={() => {
                        openEditPortfolioModal(account);
                      }}
                    >
                      Edit
                    </Button>
                  </>
                ),
              };
              arr.push(data);
            }
          });
          setPortfolios(arr);
        } else {
          openWarningNotification(
            "Data Loading Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const confirm = (account) => {
    deleteAccount(account);
  };
  const cancel = () => {};

  const deleteAccount = (account) => {
    const data = {
      account_id: account.id,
      status: 0,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "account/delete", data, config)
      .then((response) => {
        if (response.data.success) {
          openSuccessNotification(
            "Portfolio Deleted!",
            response.data.message + " Please refresh the page."
          );
          loadPortfolios();
        } else {
          openWarningNotification(
            "Portfolio Deleting Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const paginationConfig = {
    defaultPageSize: 11,
  };

  return (
    <>
      <Card className="flex flex-col bg-[#28253B] border-none min-w-full w-fit">
        <div className="flex flex-row justify-between">
          <div className="flex font-bold text-3xl"></div>
          <div className="flex flex-row gap-4">
            <div>
              <Button
                type="primary"
                icon={<PlusOutlined className="p-1 max-md:p-0" />}
                ghost
                onClick={openNewPortfolioModal}
              >
                <label className="cursor-pointer max-md:hidden visible">
                  New Account
                </label>
              </Button>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <Table
            columns={columns}
            dataSource={portfolios}
            pagination={paginationConfig}
          />
        </div>
      </Card>
      <NewPortfolio
        isOpen={isNewPortfolioOpen}
        onClose={closeNewPortfolioModal}
      />
      <EditPortfolio
        isOpen={isEditPortfolioOpen}
        onClose={closeEditPortfolioModal}
        portfolio={selectedPortfolio}
      />
    </>
  );
};

export default Portfolio;
