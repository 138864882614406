import React, { useEffect } from "react";
import { Pie, measureTextWidth } from "@ant-design/plots";

const PieChart = ({ name, wins, loss }) => {
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    );
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
              (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : "inherit"
    };">${text}</div>`;
  }

  const data = [
    {
      type: "Winners",
      value: wins,
    },
    {
      type: "Lossers",
      value: loss,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `${v} ¥`,
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: {
        textAlign: "center",
      },
      autoRotate: false,
      content: "{value}",
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : "Win Rate";
          return renderStatistic(d, text, {
            fontSize: 18,
            fontColor: "#FFF",
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "24px",
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? `${datum.value} %`
            : `${data.reduce((r, d) => r + d.value, 0)} %`;
          return renderStatistic(width, text, {
            fontSize: 24,
            fontColor: "#FFF",
          });
        },
      },
    },

    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
    ],
  };
  return (
    <div className="flex flex-col w-auto">
      <p className="flex text-blue-300 text-base pl-[20%]">{name}</p>
      <div className="flex h-[200px]">
        <Pie {...config} />
      </div>
    </div>
  );
};

export default PieChart;
