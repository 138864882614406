import { Button, Card, Divider, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useContentContext } from "../providers/ContentContext";
import axios from "axios";
import { EditStrategy, NewStrategy } from "../components";

const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Title",
    dataIndex: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Action",
    dataIndex: "actions",
  },
];

const Strategy = () => {
  let {
    url,
    openWarningNotification,
    openErrorNotification,
    openSuccessNotification,
  } = useContentContext();
  const token = localStorage.getItem("token");

  const [strategies, setStrategies] = useState([]);

  const [isNewStrategyModalOpen, setIsNewStrategyModalOpen] = useState(false);
  const [isEditStrategyModalOpen, setIsEditStrategyModalOpen] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState();

  const openNewStrategy = () => {
    setIsNewStrategyModalOpen(true);
  };

  const closeNewStrategy = () => {
    setIsNewStrategyModalOpen(false);
  };

  const openEditStrategy = (strategy) => {
    setSelectedStrategy(strategy);
    setIsEditStrategyModalOpen(true);
  };

  const closeEditStrategy = () => {
    setIsEditStrategyModalOpen(false);
  };

  useEffect(() => {
    loadStrategies();
  }, []);

  const loadStrategies = () => {
    const data = {
      user_id: localStorage.getItem("userid"),
      status: 1,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "strategy/all", data, config)
      .then((response) => {
        if (response.data.success) {
          var arr = [];
          response.data.output.forEach((strategy, x) => {
            if (strategy.is_active) {
              let data = {
                id: x + 1,
                title: strategy.title,
                description: (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: strategy.description,
                    }}
                  ></p>
                ),
                actions: (
                  <>
                    <Popconfirm
                      title="Delete the Strategy"
                      description="Are you sure to delete this strategy?"
                      onConfirm={() => {
                        confirm(strategy);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      okButtonProps={{ style: { backgroundColor: "#28253B" } }}
                      cancelText="No"
                    >
                      <Button danger className="m-1">
                        Delete
                      </Button>
                    </Popconfirm>

                    <Button
                      className="bg-yellow-600 text-white hover:text-white m-1"
                      onClick={() => {
                        openEditStrategy(strategy);
                      }}
                    >
                      Edit
                    </Button>
                  </>
                ),
              };
              arr.push(data);
            }
          });
          setStrategies(arr);
        } else {
          openWarningNotification(
            "Data Loading Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const confirm = (strategy) => {
    deleteAccount(strategy);
  };
  const cancel = () => {};

  const deleteAccount = (strategy) => {
    const data = {
      strategy_id: strategy.id,
      status: 0,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "strategy/delete", data, config)
      .then((response) => {
        if (response.data.success) {
          openSuccessNotification(
            "Strategy Deleted!",
            response.data.message + " Please refresh the page."
          );
          loadStrategies();
        } else {
          openWarningNotification(
            "Strategy Deleting Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const paginationConfig = {
    defaultPageSize: 11,
  };

  return (
    <>
      <Card className="flex flex-col bg-[#28253B] border-none min-w-full w-fit">
        <div className="flex flex-row justify-between">
          <div className="flex font-bold text-3xl">Strategy</div>
          <div className="flex flex-row gap-4">
            <div>
              <Button
                type="primary"
                icon={<PlusOutlined className="p-1 max-md:p-0" />}
                ghost
                onClick={openNewStrategy}
              >
                <label className="cursor-pointer max-md:hidden visible">
                  New Strategy
                </label>
              </Button>
            </div>
          </div>
        </div>
        <Divider />
        <div>
          <Table
            columns={columns}
            dataSource={strategies}
            pagination={paginationConfig}
          />
        </div>
      </Card>

      <NewStrategy
        isOpen={isNewStrategyModalOpen}
        onClose={closeNewStrategy}
        loadStrategies={loadStrategies}
      />

      {isEditStrategyModalOpen ? (
        <EditStrategy
          isOpen={isEditStrategyModalOpen}
          onClose={closeEditStrategy}
          loadStrategies={loadStrategies}
          strategy={selectedStrategy}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Strategy;
