import React from "react";
import { Line } from "@ant-design/plots";

const AreaChart = ({ data }) => {

  const config = {
    data,
    padding: "auto",
    xField: "Date",
    yField: "scales",
    annotations: [
      {
        type: "regionFilter",
        start: ["min", "-99999999999999"],
        end: ["max", "0"],
        color: "#F4664A",
      },
      {
        type: "line",
        start: ["min", "0"],
        end: ["max", "0"],
        style: {
          stroke: "#F4664A",
          lineDash: [2, 2],
        },
      },
    ],
  };

  return <Line {...config} />;
};

export default AreaChart;
