import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { AreaChart, PieChart } from "../components";
import { useContentContext } from "../providers/ContentContext";
import axios from "axios";

const Dashboard = () => {
  let {
    url,
    openWarningNotification,
    openErrorNotification
  } = useContentContext();
  const token = localStorage.getItem("token");

  const [data, setData] = useState();
  const [winByTradesWins, setWinByTradesWins] = useState(50);
  const [winByTradesLoss, setWinByTradesLoss] = useState(50);
  const [winByDatesWins, setWinByDatesWins] = useState(50);
  const [winByDatesLoss, setWinByDatesLoss] = useState(50);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const data = {
      user_id: localStorage.getItem("userid"),
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "dashboard/data", data, config)
      .then((response) => {
        if (response.data.success) {
          setData(response.data.output);
          setWinByTradesWins(
            response.data.output?.winning_by_trades.win_trade_precentage
          );
          setWinByTradesLoss(
            response.data.output?.winning_by_trades.loss_trade_precentage
          );
          setWinByDatesWins(
            response.data.output?.winning_by_dates.win_trade_precentage
          );
          setWinByDatesLoss(
            response.data.output?.winning_by_dates.loss_trade_precentage
          );

          let arr = [];
          response.data.output?.daily_pl.forEach((dat) => {
            let data = {
              Date: dat.close_date,
              scales: dat.profit_lost,
            };
            arr.push(data);
          });
          setChartData(arr);
        } else {
          openWarningNotification(
            "Data Loading Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  return (
    <>
      <div className="flex flex-col w-full bg-[#525252]">
        <Card className="flex flex-row bg-[#28253B] border-none w-full">
          <div className="flex sm:flex-row flex-col w-full gap-8">
            <div className="flex flex-col p-2 border-[1px] border-[#1c1e91] rounded-md shadow-sm w-full">
              <p className="flex text-blue-300 text-base">Net P&L</p>
              <div className="flex text-2xl text-white font-semibold">
                ${data?.net_pl}
              </div>
            </div>
            <div className="flex flex-col p-2 border-[1px] border-[#1c1e91] rounded-md shadow-sm w-full">
              <p className="flex text-blue-300 text-base">Accounts</p>
              <div className="flex text-2xl text-white font-semibold">
                {data?.account_count}
              </div>
            </div>
            <div className="flex flex-col p-2 border-[1px] border-[#1c1e91] rounded-md shadow-sm w-full">
              <p className="flex text-blue-300 text-base">Trades Taken</p>
              <div className="flex text-2xl text-white font-semibold">
                {data?.trades_count}
              </div>
            </div>
            <div className="flex flex-col p-2 border-[1px] border-[#1c1e91] rounded-md shadow-sm w-full">
              <p className="flex text-blue-300 text-base">Win Rate</p>
              <div className="flex text-2xl text-white font-semibold">
                {data?.win_rate}%
              </div>
            </div>
          </div>
          <div className="flex 2xl:flex-row flex-col w-full mt-8">
            <div className="flex lg:flex-row flex-col lg:w-1/2">
              <div className="flex flex-col">
                <PieChart
                  name="Winning % by Trades"
                  wins={winByTradesWins}
                  loss={winByTradesLoss}
                />
              </div>
              <div className="w-[100px]"></div>
              <div className="flex flex-col">
                <PieChart
                  name="Winning % by Days"
                  wins={winByDatesWins}
                  loss={winByDatesLoss}
                />
              </div>
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              <p className="flex text-blue-300 text-base">
                Daily Net Cumulative P&L
              </p>
              <AreaChart data={chartData} />
            </div>
          </div>
          <div className="flex flex-row w-full mt-4"></div>
        </Card>
      </div>
    </>
  );
};

export default Dashboard;
