import { Button, Card, Divider, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useContentContext } from "../providers/ContentContext";
import axios from "axios";
import { EditLog } from "../components";

const columns = [
  {
    title: "#",
    dataIndex: "id",
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Open Date",
    dataIndex: "open_date",
  },
  {
    title: "Symbol",
    dataIndex: "symbol",
  },

  {
    title: "Close Date",
    dataIndex: "close_date",
  },
  {
    title: "Entry Price",
    dataIndex: "entry_price",
  },
  {
    title: "Profit/Loss",
    dataIndex: "profit",
  },
  {
    title: "R:R",
    dataIndex: "rr",
  },
  {
    title: "T.P",
    dataIndex: "tp",
  },
  {
    title: "Long/Short",
    dataIndex: "duration",
  },
  {
    title: "S.L",
    dataIndex: "sl",
  },
  {
    title: "Notes",
    dataIndex: "notes",
  },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: "Action",
    dataIndex: "actions",
  },
];

const TradeLog = () => {
  let {
    url,
    openWarningNotification,
    openErrorNotification,
    openSuccessNotification,
  } = useContentContext();
  const token = localStorage.getItem("token");

  const [trades, setTrades] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedTrade, setSelectedTrade] = useState();

  useEffect(() => {
    loadTrades();
  }, []);

  const loadTrades = () => {
    const data = {
      user_id: localStorage.getItem("userid"),
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "account/transaction/close", data, config)
      .then((response) => {
        if (response.data.success) {
          var arr = [];
          response.data.output.forEach((transaction, x) => {
            let data = {
              id: x + 1,
              open_date: transaction.open_date,
              symbol: transaction.symbol,
              close_date: transaction.close_date,
              entry_price: transaction.entry_price,
              profit: transaction.profit_lost,
              rr: transaction.rr,
              tp: transaction.tp,
              duration:
                transaction.long_short === 1 ? (
                  <label className="text-red-400">Long</label>
                ) : (
                  <label className="text-yellow-400">Short</label>
                ),
              sl: transaction.sl,
              notes: transaction.notes,
              status: transaction.status === 1 ? "Open" : "Close",
              actions: (
                <div className="flex flex-row">
                  <Popconfirm
                    title="Delete the Trade"
                    description="Are you sure to delete this trade?"
                    onConfirm={() => {
                      confirm(transaction);
                    }}
                    onCancel={cancel}
                    okText="Yes"
                    okButtonProps={{ style: { backgroundColor: "#28253B" } }}
                    cancelText="No"
                  >
                    <Button danger>Delete</Button>
                  </Popconfirm>
                  <Button
                    className="bg-yellow-600 text-white hover:text-white ml-1"
                    onClick={() => {
                      openEditModal(transaction);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              ),
            };
            arr.push(data);
          });
          setTrades(arr);
        } else {
          openWarningNotification(
            "Data Loading Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const confirm = (trade) => {
    deleteTrade(trade);
  };
  const cancel = () => {};

  const openEditModal = (tran) => {
    setIsEditOpen(true);
    setSelectedTrade(tran);
  };

  const closeEditModal = () => {
    setIsEditOpen(false);
  };

  const deleteTrade = (trade) => {
    const data = {
      account_transaction_id: trade.id,
      status: 0,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "account/transaction/delete", data, config)
      .then((response) => {
        if (response.data.success) {
          openSuccessNotification(
            "Trade Deleted!",
            response.data.message + " Please refresh the page."
          );
          loadTrades();
        } else {
          openWarningNotification(
            "Trade Deleting Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  const paginationConfig = {
    defaultPageSize: 11,
  };

  return (
    <>
      <Card className="flex flex-col bg-[#28253B] border-none min-w-full w-fit">
        <div className="flex flex-row justify-between">
          <div className="flex font-bold text-3xl">Trade Log</div>
        </div>
        <Divider />
        <div>
          <Table
            columns={columns}
            dataSource={trades}
            pagination={paginationConfig}
          />
        </div>
      </Card>
      {isEditOpen ? (
        <EditLog
          isOpen={isEditOpen}
          onClose={closeEditModal}
          trade={selectedTrade}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TradeLog;
