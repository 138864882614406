import React from "react";
import { Menu } from "antd";
import { Logo, LogoSmall } from "../assets";
import { useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  UserOutlined,
  IdcardOutlined,
  PoweroffOutlined,
  TeamOutlined,
  DollarOutlined,
  FormOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";

const Sidebar = ({ collapsed, updatePath }) => {
  let path = window.location.pathname;
  const navigate = useNavigate();

  const selectedKey = () => {
    if (path === "/dashboard") {
      return "1";
    } else if (path === "/services") {
      return "2";
    } else if (path === "/portfolio") {
      return "3";
    } else if (path === "/strategy") {
      return "4";
    } else if (path === "/trade-assistant") {
      return "5";
    } else if (path === "/tradelog") {
      return "6";
    } else if (path === "/calender") {
      return "7";
    } else {
      return "1";
    }
  };

  const goto = (url) => {
    updatePath(url);
    navigate(url);
  };

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center py-4 bg-[#1E2128]">
        <img
          src={collapsed ? LogoSmall : Logo}
          alt="logo"
          className={`${collapsed ? "w-16 h-16" : "w-48"} mt-4`}
        />
      </div>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[selectedKey()]}
        className="text-base font-normal text-white bg-[#1E2128] mt-8 top-0"
        items={[
          {
            key: "1",
            icon: <AppstoreOutlined />,
            label: (
              <div
                onClick={() => {
                  goto("/dashboard");
                }}
              >
                Dashboard
              </div>
            ),
          },
          {
            key: "2",
            icon: <UserOutlined />,
            label: (
              <div
                onClick={() => {
                  goto("/services");
                }}
              >
                Services
              </div>
            ),
          },
          {
            key: "3",
            icon: <QrcodeOutlined />,
            label: (
              <div
                onClick={() => {
                  goto("/portfolio");
                }}
              >
                Portfolio
              </div>
            ),
          },
          {
            key: "4",
            icon: <DollarOutlined />,
            label: (
              <div
                onClick={() => {
                  goto("/strategy");
                }}
              >
                Strategy
              </div>
            ),
          },
          {
            key: "5",
            icon: <IdcardOutlined />,
            label: (
              <div
                onClick={() => {
                  goto("/trade-assistant");
                }}
              >
                Trade Assistant
              </div>
            ),
          },
          {
            key: "6",
            icon: <TeamOutlined />,
            label: (
              <div
                onClick={() => {
                  goto("/tradelog");
                }}
              >
                Trade Log
              </div>
            ),
          },
          {
            key: "7",
            icon: <FormOutlined />,
            label: (
              <div
                onClick={() => {
                  goto("/calender");
                }}
              >
                Calender
              </div>
            ),
          },
        ]}
      />
      <Menu
        theme="dark"
        mode="inline"
        className="absolute text-base font-normal text-white bg-[#1E2128] bottom-4 -ml-2"
        items={[
          {
            icon: <PoweroffOutlined className="w-2" />,
            label: (
              <div
                onClick={() => {
                  localStorage.clear();
                  window.location.replace("/login");
                }}
              >
                Logout
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default Sidebar;
