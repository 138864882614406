import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { useContentContext } from "../providers/ContentContext";
import axios from "axios";

const CheckoutForm = ({ data, pack, handleCancel }) => {
  let {
    url,
    openWarningNotification,
    openErrorNotification,
    openSuccessNotification,
  } = useContentContext();

  const token = localStorage.getItem("token");

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: `${window.location.origin}/complete` },
      redirect: "if_required",
    });

    if (error) {
      setSuccess(false);
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      setSuccess(true);
      setMessage("Payment Succeeded! 🎉");
      confirmPayment(paymentIntent);
    } else {
      setSuccess(false);
      setMessage("Unexpeceted State");
    }

    setIsProcessing(false);
  };

  const confirmPayment = (intent) => {
    const paydata = {
      user_id: data.user_id,
      package_id: pack.id,
      amount: data.amount,
      month_count: data.months,
      payment_id: intent.id,
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "transaction/add", paydata, config)
      .then((response) => {
        if (response.data.success) {
          handleCancel();
          window.location.replace("/services");
          openSuccessNotification(
            "Payment Successfull!",
            response.data.message
          );
        } else {
          openWarningNotification("Action Failed!", response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          disabled={isProcessing}
          id="submit"
          type="submit"
          className="mt-4 border-[1px] bg-blue-200 hover:bg-blue-400 px-2 py-1 border-blue-600 rounded-md"
        >
          <span id="button-text" className="text-md font-medium">
            {isProcessing ? "Processing..." : "Pay Now"}
          </span>
        </button>
      </form>
      <div
        className={`${
          success ? "text-green-700" : "text-red-600"
        } flex text-sm w-full justify-center text-center`}
      >
        {message}
      </div>
    </>
  );
};

export default CheckoutForm;
