import React, { useEffect, useState } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import axios from "axios";
import { useContentContext } from "../providers/ContentContext";

const Schedule = () => {
  let {
    url,
    openWarningNotification,
    openErrorNotification,
  } = useContentContext();
  const token = localStorage.getItem("token");

  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const dataObj = {
      user_id: localStorage.getItem("userid")
    };

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(url + "calendar/data", dataObj, config)
      .then((response) => {
        if (response.data.success) {
          let arr = [];
          response.data.output.forEach((dat, x) => {
            let data = {
              event_id: x + 1,
              title: dat.title,
              start: new Date(dat.date + " 23:30"),
              end: new Date(dat.date + " 00:30"),
              allDay: true,
              editable: false,
              deletable: false,
              draggable: false,
              color: dat.isProfit ? "#50b500" : "#900000",
            };
            arr.push(data);
          });
          setData(arr);
        } else {
          openWarningNotification(
            "Data Loading Failed!",
            response.data.message + " Please refresh the page."
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openErrorNotification("Error!", error.response.data.message);
          localStorage.clear();
          window.location.replace("/login");
        } else {
          openErrorNotification("Error!", error.response.data.message);
        }
      });
  };

  return (
    <>
      {data.length !== 0 ? (
        <Scheduler view="month" viewModes={["month"]} events={data} />
      ) : (
        <>Loading...</>
      )}
    </>
  );
};

export default Schedule;
