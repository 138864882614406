import { Card } from 'antd'
import React from 'react'
import { Schedule } from '../components'

const Calender = () => {
  return (
    <div className="flex w-full bg-[#525252]">
      <Card className="flex bg-[#28253B] border-none w-full">
          <Schedule />
      </Card>
    </div>
  )
}

export default Calender